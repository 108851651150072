<template>
  <div class="lnvitationcode">
    <div class="userInfo-popup" v-if="popupShow">复制成功</div>
    <div class="lnvitationcode-pagw">
      <div class="">
        <div class="lnvitationcode-title">您的邀请码</div>
        <div class="lnvitationcode-text">
          <div>{{ userInfo.userId }}</div>
          <div class="lnvitationcode-ww flex items-center flex-center">
            <img
              src="https://cdn.bubbleplan.cn/static/user/Mask234123group2x.png"
              alt=""
              class="lnvitationcode-ww-img"
              @click="copyTextButton(userInfo.userId, '邀请码')"
            />
          </div>
        </div>
      </div>
      <div class="" v-if="!superiors">
        <div class="upperLevelUser">绑定邀请码</div>
        <el-input
          v-model="superiorUserId"
          placeholder="请输入上级用户uid"
        ></el-input>
        <div class="confirmAddition" @click="confirmAddition">确认</div>
      </div>
      <div v-else class="">
        <div class="upperLevelUser">邀请用户uid</div>
        <div class="lnvitationcode-text upperLevelUsertop">
          {{ superiorUserId }}
        </div>
      </div>
    </div>
    <div class="lnvitationcode-ul">
      <div class="lnvitationcode-title">邀请列表</div>
      <div class="lnvitationcode-list flex flex-wrap" @scroll="handleScrolls">
        <div
          v-for="(item, index) in assignmentList"
          :key="index"
          class="lnvitationcode-list-li"
          @click="viewSubordinates(item, index)"
        >
          <div
            class="lnvitationcode-list-li-text flex"
            style="align-items: center"
          >
            <div>{{ item.invitationUserId }}</div>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/mine/Group102531123.png"
              alt=""
              class="lnvitationcode-list-li-img"
            />
            <div class="lnvitationcode-list-li-autonym">
              {{ item.isSign == 1 ? "已实名" : "未实名" }}
            </div>
            <div
              class="lnvitationcode-list-li-num flex"
              style="align-items: center"
            >
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/mine/Group1067.png"
                mode=""
                class="lnvitationcode-list-li-img2"
              />

              <div class="lnvitationcode-list-li-text2">
                {{ item.buyNum }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="subordinateShow"
      width="30%"
      :show-close="false"
    >
      <div class="subordinate" @scroll="handleScroll">
        <div class="lnvitationcode-list-li flex flex-wrap" style="width: 100%">
          <div
            v-for="(item, index) in subordinateList"
            :key="index"
            class="lnvitationcode-list-li-text botterw"
          >
            <div class="flex" style="align-items: center">
              <div style="color: #6d689a">{{ item.invitationUserId }}</div>

              <div class="lnvitationcode-list-li-autonym">
                {{ item.isSign == 1 ? "已实名" : "未实名" }}
              </div>
              <div
                class="lnvitationcode-list-li-num flex"
                style="align-items: center"
              >
                <img
                  src="https://cdn.bubbleplan.cn/m_bubble/mine/Group1067.png"
                  mode=""
                  class="lnvitationcode-list-li-img2"
                />

                <div class="lnvitationcode-list-li-text2">
                  {{ item.buyNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: Object,
  },
  data() {
    return {
      subordinateList: [],
      pages: {
        pageNum: 1,
        pageSize: 20,
      },
      invitationUserId: "",
      hasNextPages: false,
      subordinateShow: false,
      popupShow: false,
      superiorUserId: "",
      page: {
        pageNum: 1,
        pageSize: 50,
      },
      hasNextPage: false,
      superiors: false,
      assignmentList: [],
    };
  },
  mounted() {
    this.mySuperior();
    this.page.pageNum = 1;
    this.mySubordinates();
  },
  methods: {
    async viewSubordinates(item, index) {
      this.invitationUserId = item.invitationUserId;
      this.subordinateList = [];
      this.subordinateScrolltolower();
      this.subordinateShow = true;
    },
    async subordinateScrolltolower(type) {
      const params = {
        ...this.pages,
        queryUserId: this.invitationUserId,
      };
      const { data } = await this.$ajax.mySubordinates(params);
      this.hasNextPages = data.hasNextPage;
      if (type == "bottom") {
        this.subordinateList.push(...data.list);
      } else {
        this.subordinateList = data.list;
      }
    },
    //复制
    copyTextButton(text, name) {
      if (!text) {
        this.$message.error(`复制内容不能为空`);
        return;
      }
      text = text.toString();
      this.$copyText(text)
        .then(() => {
          this.popupShow = true;
          setTimeout(() => {
            this.popupShow = false;
          }, 1500);
        })
        .catch(() => {
          this.$message.error(`${name}复制失败`);
        });
    },
    //查看上级
    async mySuperior() {
      const { data } = await this.$ajax.mySuperior();
      if (data) {
        this.superiors = true;
        this.superiorUserId = data;
      } else {
        this.superiors = false;
      }
    },

    //查看下级
    async mySubordinates(type) {
      const params = {
        ...this.page,
      };
      const { data } = await this.$ajax.mySubordinates(params);
      this.hasNextPage = data.hasNextPage;
      if (type == "bottom") {
        this.assignmentList.push(...data.list);
      } else {
        this.assignmentList = data.list;
      }
    },
    //绑定邀请人
    async confirmAddition() {
      if (!this.superiorUserId) return;
      const { data, code } = await this.$ajax.bindingSuperior({
        superiorUserId: this.superiorUserId,
      });
      this.mySuperior();
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部

      if (scrollTop + clientHeight >= scrollHeight) {
        this.page.pageNum++;
        this.mySubordinates("bottom");
      }
    },
    handleScrolls() {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部

      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPages) {
          this.pages.pageNum++;
          this.subordinateScrolltolower("bottom");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lnvitationcode {
  padding: 50px 56px;
  position: relative;
  .userInfo-popup {
    width: 750px;
    height: 52px;
    flex-shrink: 0;
    background: linear-gradient(
      90deg,
      #5a50cf00 0%,
      #8277ff 23%,
      #8a7fff 76%,
      #5a50cf00 99.5%
    );
    position: absolute;
    top: 0;
    left: 71px;
    margin: -17px;
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    font-family: "TsangerYuMo";
    font-weight: bold;
    line-height: 52px;
  }
  &-pagw {
    width: 100%;
    height: 342px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vecto1231r5472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 25px 93px;
  }
  &-ul {
    width: 100%;
    height: 294px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vecto1231r5472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 23px 30px;
    margin-top: 23px;
  }
  .lnvitationcode-title {
    font-family: "TsangerYuMo";
    color: #fef5de;
    font-size: 24px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
      2px 2px 0 #341030;
    /* 模拟描边效果 */
    margin-bottom: 13px;
  }

  .lnvitationcode-text {
    width: 100%;
    height: 63px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
      no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
    font-size: 23px;
    color: #d9d2e4;
    text-align: center;
    font-family: "TsangerYuMo";
    font-weight: bold;
    line-height: 63px;
    position: relative;
  }
  .lnvitationcode-ww {
    // width: 60px;
    // height: 60px;
    // border-radius: 0 10px 10px 0;
    // opacity: 1;
    // background: #3d5566;
    position: absolute;
    top: 14px;
    right: 42px;
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 27.27px;
      height: 33px;
    }
  }

  .confirmAddition {
    width: 144px;
    height: 61px;
    background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
      no-repeat;
    background-size: 100% 100%;
    border: none;
    margin: auto;
    color: #f1eae2;
    font-family: "TsangerYuMo";
    font-weight: bold;
    text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
      2px 2px 0 #533690;
    /* 模拟描边效果 */
    line-height: 61px;
    text-align: center;
    font-size: 26px;
    margin-top: 15px;
  }

  :deep(.el-input__inner) {
    height: 63px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
      no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
    font-size: 23px;
    color: #d9d2e4;
    text-align: center;
    font-family: "TsangerYuMo";
    font-weight: bold;
  }

  .lnvitationcode-list {
    margin-top: 4px;
    width: 100%;
    height: 211px;
    overflow: auto;
    background: url("https://cdn.bubbleplan.cn/static/user/Ve1231ctor5642x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 20px 33px;
    &-li {
      width: 50%;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      height: 62px;
      text-align: center;
      &-text {
        color: #d9d2e4;
        font-size: 22px;
        font-weight: Bold;
      }
      &-img {
        width: 27px;
        height: 27px;
        margin-left: 10px;
        margin-right: 12px;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-autonym {
        width: 91.59px;
        height: 36.79px;
        text-align: center;
        border-radius: 135.14px;
        background: #5f4e81;
        line-height: 36.79px;
        color: #d9d2e4;
        font-size: 21px;
        font-weight: 500;
        // margin-left: 16rpx;
      }
      &-num {
        margin-left: 10px;
        min-width: 90px;
        height: 36.79px;
        border-radius: 135.14px;
        background: #79689b;
      }
      &-img2 {
        width: 41px;
        height: 45px;
      }
      &-text2 {
        font-size: 21px;
        font-weight: 500;
        text-align: center;
        margin-left: 6px;
      }
    }
  }

  .lnvitationcode-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .upperLevelUser {
    font-family: "TsangerYuMo";
    color: #fef5de;
    font-size: 24px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
      2px 2px 0 #341030;
    /* 模拟描边效果 */
    margin-bottom: 13px;
    margin-top: 25px;
  }
  .upperLevelUsertop {
    margin-top: 18px;
    margin-left: 0px !important;
  }
  .subordinate {
    width: 500px;
    height: 577px;
    background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Gro131up10254.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 43px 37px 101px;
    .botterw {
      margin-bottom: 10px;
    }
    .botterw .lnvitationcode-list-li-autonym {
      margin-left: 10px;
    }
    .lnvitationcode-list-li {
      height: 70px;
      border-bottom: none;
    }
  }
}
</style>
