<template>
  <div class="autonym">
    <div class="autonym-pagw">
      <template v-if="realname">
        <div class="autonym-list">
          <div class="li">
            <div class="flex flex-between">
              <div style="color: #d9d2e4; font-size: 22px">
                姓名：{{ realname.idCardName }}
              </div>
              <div style="color: #d9d2e4; font-size: 22px; font-weight: 700">
                身份证号码：{{ realname.idCardNumber }}
              </div>
            </div>
            <div
              style="margin-top: 17px; color: #d9d2e4; font-size: 20px"
              v-if="userInfo.userTel"
            >
              手机号：{{ userInfo.userTel }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <el-form
          :model="userForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item label="姓名" prop="idCardName">
            <el-input v-model="userForm.idCardName"></el-input>
          </el-form-item>
          <el-form-item
            label="身份证号"
            prop="idCardNumber"
            style="margin-top: 40px"
          >
            <el-input v-model="userForm.idCardNumber"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="userTel" style="margin-top: 40px">
            <div class="flex">
              <el-input v-model="userForm.userTel"></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="验证码"
            prop="verificationCode"
            style="margin-top: 40px"
          >
            <div class="flex">
              <el-input v-model="userForm.verificationCode"></el-input>
              <el-button type="success" @click="getcode()" v-if="isgetcode">{{
                text
              }}</el-button>
              <div v-else class="countdownss" style="">{{ countdown }}秒</div>
            </div>
          </el-form-item>
        </el-form>
        <div class="userInfo-name-button">
          <el-button type="success" :disabled="isdisabled" @click="storage()"
            >保存</el-button
          >
        </div>
        <el-dialog
          title=""
          :visible.sync="base64ImageShow"
          width="30%"
          @close="closeBase64"
          :show-close="false"
        >
          <div class="base64ImageClass">
            <div class="flex">
              <el-input v-model="validate" placeholder="请输入验证码">
                <div
                  slot="prefix"
                  style="height: 100%; display: flex; align-items: center"
                >
                  <img
                    src="https://cdn.bubbleplan.cn/static/login/Frame2x.png"
                    alt=""
                    style=""
                    class="register-input-imgew"
                  />
                </div>
              </el-input>
              <div class="flex" style="margin-left: 10px; align-items: center">
                <img
                  :src="base64Image"
                  alt="Base64 Image"
                  class="base64ImageClass-img"
                />
                <img
                  src="https://cdn.bubbleplan.cn/static/home/refresh.png"
                  alt=""
                  style="width: 28px; height: 28px; margin-left: 10px"
                  class="base64ImageClass-refresh"
                  @click="getImgBase64(1)"
                />
              </div>
            </div>
            <div
              style="margin-top: 43px; justify-content: space-evenly"
              class="flex"
            >
              <div class="base64ImageClass-button" @click="closeBase64()">
                取消
              </div>
              <div @click="countersign()" class="base64ImageClass-button">
                确认
              </div>
            </div>
          </div>
        </el-dialog>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    realname: Object,
    userInfo: Object,
  },
  data() {
    return {
      userForm: {},
      rules: {
        idCardName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        idCardNumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          { min: 15, max: 18, message: "请输入正确身份证号", trigger: "blur" },
        ],
        userTel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      text: "获取",
      countdown: 60,
      isdisabled: true,
      isgetcode: true,
      timerInterval: null,
      base64Image: null,
      base64ImageShow: false,
      captchaKey: null,
      validate: "",
    };
  },
  watch: {
    userForm: {
      handler: function (newValue, oldVal) {
        if (
          newValue.idCardName &&
          newValue.idCardNumber &&
          newValue.userTel &&
          newValue.verificationCode
        ) {
          this.isdisabled = false;
        } else {
          this.isdisabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    closeBase64() {
      this.base64ImageShow = false;
      this.validate = "";
    },
    countersign() {
      if (!this.validate) {
        this.$message.error("请输入验证码");
        return;
      }
      // this.validate = "";
      this.countdown = 60;
      this.sendSms();
    },
    //获取图片验证码
    async getImgBase64(type) {
      const params = {
        key: this.mail,
      };
      const { data, code } = await this.$ajax.getImgBase64(params);
      if (code == 200) {
        this.base64Image = data.base64Image;
        this.captchaKey = data.captchaKey;
        if (type != 1) {
          this.base64ImageShow = true;
        }
      }
    },
    //实名
    async realNameAli() {
      const params = {
        ...this.userForm,
      };
      const { data, code } = await this.$ajax.realNameAli(params);
      if (code == 200) {
        this.$emit("getRealNameInfo");
        this.$emit("getUserInfo");
        this.$refs.ruleForm.resetFields();
        this.userForm = {};
      }
    },

    storage() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.realNameAli();
        } else {
          return false;
        }
      });
    },
    getcode() {
      if (!this.$reg.phone.test(this.userForm.userTel)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      this.getImgBase64();
      return;
      this.countdown = 60;
      this.sendSms();
    },

    //发送验证码
    async sendSms() {
      const params = {
        userTel: this.userForm.userTel,
        key: this.captchaKey,
        validate: this.validate,
      };
      const { data, code } = await this.$ajax.sendSms(params);
      if (code == 200) {
        this.base64ImageShow = false;
        this.timerInterval = setInterval(this.updateTimer, 1000);
        this.isgetcode = false;
      }
      this.validate = "";
    },
    //倒计时
    updateTimer() {
      var minutes = Math.floor(this.countdown / 60);
      var seconds = this.countdown % 60;

      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // seconds = seconds < 10 ? "0" + seconds : seconds;
      // this.countdown = minutes + ":" + seconds
      // document.getElementById("timer").textContent = minutes + ":" + seconds;

      if (this.countdown == 0) {
        clearInterval(this.timerInterval);
        // 验证码过期的逻辑
        this.text = "重新获取";
        this.isgetcode = true;
      } else {
        this.countdown--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.autonym {
  padding: 50px 56px;
  &-pagw {
    width: 942px;
    height: 631px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 25px 91px;
  }

  .autonym-list {
    height: 500px;
    overflow: auto;

    .li {
      width: 100%;
      height: 145px;
      flex-shrink: 0;
      background: url("https://cdn.bubbleplan.cn/static/user/Vect123or5642x.png")
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
      padding: 34px 30px;
    }
  }

  :deep(.el-input__inner) {
    width: 100%;
    height: 63px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
      no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
    font-size: 23px;
    color: #d9d2e4;
    text-align: center;
    font-family: "TsangerYuMo";
    font-weight: bold;
  }

  // :deep(.el-input) {
  //   width: 36%;
  // }
  :deep(.el-form-item) {
    margin-bottom: 25px;
    margin-top: 0 !important;
  }

  :deep(.el-form-item__label) {
    font-family: "TsangerYuMo";
    color: #fef5de;
    font-size: 24px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
      2px 2px 0 #341030;
    /* 模拟描边效果 */
  }

  :deep(.el-button) {
    margin-left: 13px;
    width: 149px;
    height: 72px;
    background: url("https://cdn.bubbleplan.cn/static/user/Frame9123126192x.png")
      no-repeat;
    background-size: 100% 100%;
    color: #f1eae2;
    text-align: center;
    line-height: 52px;
    font-size: 23px;
    font-weight: Bold;
    border: none;
  }
  :deep(.el-form-item__error) {
    left: 46% !important;
    top: 110% !important;
  }

  .userInfo-name-button {
    width: 100%;
    margin-top: 29px;
    display: flex;
    :deep(.el-button) {
      margin: auto;
      width: 144px;
      height: 61px;
      background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      margin: auto;
      color: #f1eae2;
      font-family: "TsangerYuMo";
      font-weight: bold;
      text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
        2px 2px 0 #533690;
      /* 模拟描边效果 */
      font-size: 26px;
      line-height: 41px;
      margin-top: 30px;
    }

    .el:deep(-button--success.is-disabled) {
      background: rgba(218, 255, 0, 0.4);
      border: none;
    }
  }
  :deep(.el-dialog) {
    border-radius: 20px;
    background: transparent;
  }
  :deep(.el-dialog__headerbtn) {
    font-size: 20px;
  }
  .base64ImageClass {
    width: 484px;
    height: 198px;
    background: url("https://cdn.bubbleplan.cn/static/login/Group103612x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 46px 31px;

    // :deep(.el-input) {
    //   width: 280px;
    //   height: 58px;
    // }
    :deep(.el-input--small .el-input__inner) {
      width: 280px;
      height: 58px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector5602x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      color: #574352;
      font-size: 20px;
      font-weight: Bold;
      padding-left: 50px;
    }
    :deep(.el-input__inner)::placeholder {
      color: #574352;
      font-size: 20px;
      font-weight: Bold;
    }
    &-img {
      width: 107px;
      height: 48px;
    }
    &-button:nth-child(2) {
      width: 140px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector6072x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 56px;
      color: #f3e9d0;
      font-family: "TsangerYuMo";
      font-size: 24px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      text-align: center;
    }
    &-button {
      width: 140px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vec123tor6062x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 56px;
      color: #f3e9d0;
      font-family: "TsangerYuMo";
      font-size: 24px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      text-align: center;
    }
    &-refresh:hover {
      cursor: pointer;
    }
    &-button:hover {
      cursor: pointer;
    }
  }
  .countdownss {
    width: 151px;
    height: 69px;
    background: url("https://cdn.bubbleplan.cn/static/login/Frame96192x.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 69px;
    margin-left: 18px;
    color: #f1eae2;
    font-size: 20px;
    font-weight: Bold;
    border: none;
  }
}
</style>