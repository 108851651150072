<template>
  <div class="wallet">
    <div class="wallet-bagw">
      <div class="wallet-pay" @click="wallet()">
        <div class="wallet-pay-title flex items-center">
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/mine/image6812x.png"
            mode=""
            class="wallet-pay-img"
          />

          <div class="wallet-pay-title">汇付</div>
        </div>
        <div class="flex flex-end">
          <div class="wallet-pay-button">
            {{ userInfo.accountOpenHF ? "已开通" : "未开通" }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="wallet-pay1">
            <div class="wallet-pay-title">支付</div>
            <div class="flex flex-end">
                <div class="wallet-pay-button">已开通</div>

            </div>
        </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //获取用户信息
    async getUserInfo() {
      const { data } = await this.$ajax.getUserInfo();
      localStorage.setItem("user_info", JSON.stringify(data));
      this.userInfo = data;
      // this.$store.commit("userSuerInfo", data);
      this.userSuerInfo(data);
      // console.log(this.suerInfo);
    },
    wallet() {
      if (this.userInfo.accountOpenPayHF) {
        this.hfAccountOpening();
      } else {
        this.hfAccountOpenPay();
      }
    },
    //进入钱包
    async hfAccountOpening() {
      const { data, code } = await this.$ajax.hfAccountOpening();
      if (code == 200) {
        location.href = data;
      }
    },
    //汇付钱包开户缴费2元
    async hfAccountOpenPay() {
      const { data, code } = await this.$ajax.hfAccountOpenPay({
        systemType: "PC",
      });
      if (code == 200) {
        location.href = data;
      }
    },
  },
};
</script>
<style lang="scss">
.wallet {
  // width: 892px;
  // height: 698px;
  flex-shrink: 0;
  border-radius: 20px;
  // background: #13212f;
  // padding: 30px;
  padding: 50px 56px 70px 56px;

  &-bagw {
    width: 100%;
    height: 610px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 32px 35px;
  }

  .wallet-pay {
    width: 470px;
    height: 160px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group100222x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 26px 26px 20px 28px;
    &-img {
      width: 30px;
      height: 30px;
      margin-right: 13px;
    }

    &-title {
      color: #ffffff;
      font-size: 24px;
    }

    .wallet-pay-title {
      color: #ffffff;
      font-size: 24px;
    }
    .wallet-pay-button:hover {
      cursor: pointer;
    }
    .wallet-pay-button {
      width: 127px;
      height: 46px;
      flex-shrink: 0;
      border-radius: 190px;
      background: #10061e;
      color: #daff00;
      font-size: 18px;
      text-align: center;
      line-height: 46px;
      margin-top: 37px;
    }
  }

  .wallet-pay1 {
    width: 470px;
    height: 160px;
    background: url("../../../img/user/pay1.png") no-repeat;
    background-size: 100% 100%;
    padding: 26px 26px 20px 28px;
    margin-top: 20px;

    .wallet-pay-title {
      color: #ffffff;
      font-size: 24px;
    }

    .wallet-pay-button {
      width: 127px;
      height: 46px;
      flex-shrink: 0;
      border-radius: 190px;
      background: #10061e;
      color: #daff00;
      font-size: 18px;
      text-align: center;
      line-height: 46px;
      margin-top: 37px;
    }
  }
}
</style>