<template>
  <div class="user flex">
    <img
      src="https://cdn.bubbleplan.cn/static/home/backPage.png"
      alt=""
      class="user-bach"
      @click="back()"
    />
    <div class="user-left">
      <div
        v-for="(item, index) in menusList"
        :key="index"
        class="user-left-li"
        :class="menusListIndex == index ? 'optfor' + index : ''"
        @click="menusListButton(index)"
      >
        <div class="user-left-li-name">{{ item.name }}</div>
      </div>
    </div>
    <div class="user-right">
      <!-- 用户信息 -->
      <userInfo
        v-if="menusListIndex == 0"
        :userInfo="userInfo"
        @updateInfo="updateInfo"
        @getUserInfo="getUserInfo"
      ></userInfo>
      <!-- 钱包 -->
      <wallet v-if="menusListIndex == 1"></wallet>
      <!-- 邮箱 -->
      <mailbox v-if="menusListIndex == 2" :userInfo="userInfo"></mailbox>
      <!-- 地址 -->
      <shippingAddress v-if="menusListIndex == 3"></shippingAddress>
      <!-- 实名 -->
      <autonym
        v-if="menusListIndex == 4"
        :realname="realname"
        :userInfo="userInfo"
        @getRealNameInfo="getRealNameInfo"
        @getUserInfo="getUserInfo"
      />
      <!-- 我的订单 -->
      <myOrder v-if="menusListIndex == 5"></myOrder>
      <!-- 邀请码 -->
      <lnvitationcode
        v-if="menusListIndex == 7"
        :userInfo="userInfo"
      ></lnvitationcode>

      <team
        v-if="menusListIndex == 8"
        :userInfo="userInfo"
        @getUserInfo="getUserInfo"
        ref="teamRef"
      >
      </team>

      <securitycode v-if="menusListIndex == 9"></securitycode>
    </div>

    <el-dialog
      title=""
      :visible.sync="secedeShow"
      width="30%"
      :show-close="false"
    >
      <div class="dropoutLogin">
        <div class="dropoutLogin-title">提示</div>
        <img
          src="https://cdn.bubbleplan.cn/static/user/X2x.png"
          alt=""
          class="dropoutLogin-img"
          @click="secedeShow = false"
        />
        <div class="dropoutLogin-text">是否切换用户？</div>
        <span slot="footer" class="dialog-footer flex flex-between">
          <div class="notarize" @click="dropout()"></div>
          <div @click="secedeShow = false" class="cancel"></div>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import userInfo from "./components/userInfo.vue";
import autonym from "./components/autonym.vue";
import lnvitationcode from "./components/lnvitationcode.vue";
import shippingAddress from "./components/shippingAddress.vue";
import wallet from "./components/wallet.vue";
import mailbox from "./components/mailbox.vue";
import team from "./components/team.vue";
import securitycode from "./components/securitycode.vue";
import myOrder from "./components/myOrder.vue";
export default {
  components: {
    userInfo,
    autonym,
    lnvitationcode,
    shippingAddress,
    wallet,
    mailbox,
    team,
    securitycode,
    myOrder,
  },
  data() {
    return {
      menusList: [
        {
          name: "游戏内名称",
          img: require("../../img/user/user.png"),
          img1: require("../../img/user/user_button.png"),
        },
        {
          name: "钱包",
          img: require("../../img/user/pay.png"),
          img1: require("../../img/user/pay_button.png"),
        },
        {
          name: "邮箱",
          img: require("../../img/user/mailbox.png"),
          img1: require("../../img/user/mailbox_button.png"),
        },
        {
          name: "收货地址",
          img: require("../../img/user/location.png"),
          img1: require("../../img/user/location_button.png"),
        },

        {
          name: "实名信息",
          img: require("../../img/user/autonym.png"),
          img1: require("../../img/user/autonym_button.png"),
        },
        {
          name: "我的订单",
          img: require("../../img/user/location.png"),
          img1: require("../../img/user/location_button.png"),
        },
        {
          name: "登出（切换账号）",
          img: require("../../img/user/quit.png"),
          img1: require("../../img/user/quit_button.png"),
        },
        {
          name: "邀请码",
          img: require("../../img/user/Invitationcode.png"),
          img1: require("../../img/user/Invitationcode_button.png"),
        },
        {
          name: "战队",
          img: require("../../img/user/team.png"),
          img1: require("../../img/user/team_button.png"),
        },
        {
          name: "安全密码设置",
          img: "https://cdn.bubbleplan.cn/static/user/Union(1).png",
          img1: "https://cdn.bubbleplan.cn/static/user/Union.png",
        },
      ],
      menusListIndex: 0,
      secedeShow: false,
      userInfo: {},
      realname: undefined,
    };
  },
  mounted() {
    this.getUserInfo();
    this.userNum();
    this.getRealNameInfo();
  },
  methods: {
    back() {
      history.back();
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
        this.crystalNum();
      }
    },
    //查询晶体or晶体积分
    async crystalNum() {
      const { data, code } = await this.$ajax.crystalNum();
      if (code == 200) {
        this.usercrystalNumData(data);
      }
    },
    updateInfo() {
      this.getUserInfo();
    },
    //获取用户实名
    async getRealNameInfo() {
      const { data } = await this.$ajax.getRealNameInfo();
      this.realname = data;
    },
    //获取用户信息
    async getUserInfo() {
      const { data } = await this.$ajax.getUserInfo();
      localStorage.setItem("user_info", JSON.stringify(data));
      this.userInfo = data;
      // this.$store.commit("userSuerInfo", data);
      this.userSuerInfo(data);
      this.userNum();
      // console.log(this.suerInfo);
    },

    //退出登录
    async dropout() {
      const { code } = await this.$ajax.logOff();
      this.secedeShow = false;
      if (code == 200) {
        this.usernameUser({});
        this.userSuerInfo({});
        setTimeout(() => {
          if (this.$route.path !== "/login/login") {
            this.$router.push("/login/login");
          }
        }, 500);
      }
    },
    menusListButton(index) {
      // if (index == 1) {
      //   this.$message("开发中");
      //   return;
      // }
      // if (this.menusListIndex == index) return;
      this.menusListIndex = index;
      if (index == 8) {
        this.getUserInfo();
        if (this.userInfo.isJoin) {
          setTimeout(() => {
            this.$refs.teamRef.memberList();
          }, 10);
        }
      }
      if (this.menusListIndex == 6) {
        this.secedeShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/user/user_bgc.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 30px 0px 0 319px;
  position: relative;
  .user-bach {
    position: absolute;
    width: 72px;
    height: 72px;
    top: 60px;
    left: 220px;
    z-index: 9;
  }
  .user-left::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .user-left {
    // width: 324px;
    height: 730px;
    flex-shrink: 0;
    border-radius: 20px;
    // background: #13212f;
    padding: 24px;
    overflow: auto;
    padding-right: 0;
    .user-left-li:hover {
      cursor: pointer;
    }
    .user-left-li {
      width: 100%;
      height: 60px;
      margin-bottom: 15px;
      font-size: 24px;
      color: #f1eae2;
      font-family: "TsangerYuMo";
      font-weight: 800;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      .user-left-li-img {
        width: 28px;
        height: 28px;
        margin-right: 18px;
      }
    }
    .user-left-li:nth-child(1) {
      width: 216px;
      height: 82px;
      text-align: center;
      line-height: 82px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame11.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .user-left-li:nth-child(2) {
      width: 223px;
      height: 83px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame2.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 83px;
      margin-top: -24px;
    }
    .user-left-li:nth-child(3) {
      width: 216px;
      height: 82px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame3.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 82px;
      margin-top: -20px;
    }
    .user-left-li:nth-child(4) {
      width: 216px;
      height: 86px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame4.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 86px;
      margin-top: -20px;
    }
    .user-left-li:nth-child(5) {
      width: 216px;
      height: 86px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame5.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 86px;
      margin-top: -20px;
    }
    .user-left-li:nth-child(6) {
      width: 216px;
      height: 82px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame3.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 82px;
      margin-top: -20px;
    }
    .user-left-li:nth-child(7) {
      width: 216px;
      height: 114px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame6.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 114px;
      margin-top: -20px;
    }
    .user-left-li:nth-child(8) {
      width: 216px;
      height: 82px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame8.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 82px;
      margin-top: -20px;
    }
    .user-left-li:nth-child(9) {
      width: 223px;
      height: 83px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame9.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 83px;
      margin-top: -24px;
    }
    .user-left-li:nth-child(10) {
      width: 216px;
      height: 82px;
      background: url("https://cdn.bubbleplan.cn/static/user/frame10.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 82px;
      margin-top: -24px;
    }
    .optfor {
      color: #daff00 !important;
      font-weight: 700;
      border-radius: 10px;
      // background: #021521;
    }
    .optfor0 {
      width: 216px !important;
      height: 82px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc1.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 82px;
      /* 模拟描边效果 */
    }
    .optfor1 {
      width: 223px !important;
      height: 83px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc2.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 83px;
      /* 模拟描边效果 */
    }
    .optfor2 {
      width: 216px !important;
      height: 82px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc3.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 82px;
      /* 模拟描边效果 */
    }
    .optfor3 {
      width: 216px !important;
      height: 86px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc4.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 86px;
      /* 模拟描边效果 */
    }
    .optfor4 {
      width: 216px !important;
      height: 86px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc5.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 86px;
      /* 模拟描边效果 */
    }
    .optfor5 {
      width: 216px !important;
      height: 82px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc6.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 82px;
      /* 模拟描边效果 */
    }
    .optfor6 {
      width: 216px !important;
      height: 114px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc7.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 114px;
      /* 模拟描边效果 */
    }
    .optfor7 {
      width: 216px !important;
      height: 82px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc8.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 82px;
      /* 模拟描边效果 */
    }
    .optfor8 {
      width: 223px !important;
      height: 82px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc9.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 82px;
      /* 模拟描边效果 */
    }
    .optfor9 {
      width: 216px !important;
      height: 82px !important;
      background: url("https://cdn.bubbleplan.cn/static/user/frame_cc10.png")
        no-repeat !important;
      background-size: 100% 100% !important;
      line-height: 82px;
      /* 模拟描边效果 */
    }
  }

  .user-right {
    margin-left: 19px;
    width: 1054px;
    height: 730px;
    background: url("https://cdn.bubbleplan.cn/static/user/Group104472x.png")
      no-repeat;
    background-size: 100% 100%;
  }

  :deep(.el-dialog__title) {
    color: #daff00;
    font-size: 24px;
    font-weight: 400;
  }

  :deep(.el-dialog) {
    background: transparent;
    box-shadow: none;
  }

  :deep(.el-dialog__close el-icon el-icon-close) {
    color: #64849a;
    font-size: 20px;
  }

  :deep(.el-dialog__header) {
    border-bottom: none;
  }

  .dialog-footer {
    text-align: center;
    line-height: 44px;
    font-size: 20px;
    margin-top: 76.21px;
    justify-content: space-evenly;
    .cancel:hover {
      cursor: pointer;
    }
    .notarize:hover {
      cursor: pointer;
    }
    .cancel {
      width: 141px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/user/Group102062x.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .notarize {
      width: 141px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/user/Group102072x.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .dropoutLogin {
    width: 488px;
    height: 271px;
    background: url("https://cdn.bubbleplan.cn/static/user/Group102362x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 20px 0;
    position: relative;
    &-title {
      color: #574352;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 25px;
      font-weight: bold;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 49px;
      height: 49px;
      position: absolute;
      top: 0;
      right: -12px;
    }
    &-text {
      margin-top: 75.37px;
      text-align: center;
      color: #574352;
      font-size: 22px;
      font-weight: Bold;
    }
  }
}
</style>
