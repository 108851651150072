<template>
  <div class="team">
    <div>
      <!-- 是否有战队 -->
      <template v-if="!userInfo.isJoin">
        <div class="team-applyorcreate" v-if="selectShow">
          <div class="team-pagw">
            <div class="flex team-applyorcreate-li">
              <div
                class="team-applyorcreate-bgc"
                @click="Joinorapply(0)"
                :class="
                  createorapply == 0 ? 'team-applyorcreate-bgc-border' : ''
                "
              ></div>
              <div
                class="team-applyorcreate-bgc1"
                @click="Joinorapply(1)"
                :class="
                  createorapply == 1 ? 'team-applyorcreate-bgc-border' : ''
                "
              ></div>
            </div>
            <div class="team-applyorcreate-button">
              <el-button type="success" @click="Joinorapply1()">确认</el-button>
            </div>
          </div>
        </div>
        <!-- 加入列表 -->
        <div class="team-join" v-if="JointheteamShow">
          <div class="team-join-top">
            <div class="team-join-title">加入战队</div>
            <div class="team-join-input">
              <el-input v-model="queryId" placeholder="搜索战队编号"></el-input>
              <div class="team-join-input-button" @click="queryGuildById()">
                搜索
              </div>
            </div>
          </div>
          <div class="team-join-list">
            <div class="team-join-list-type flex">
              <div class="team-join-list-type-title" style="width: 33%">
                战队名称
              </div>
              <div class="team-join-list-type-title" style="width: 33%">
                战队编号
              </div>
              <div class="team-join-list-type-title" style="width: 33%">
                状态
              </div>
            </div>
            <div class="team-join-list-ul" @scroll="handleScroll">
              <div
                v-for="(item, index) in teamList"
                :key="index"
                class="team-join-list-ul-li flex"
              >
                <div class="team-join-list-ul-li-text">
                  {{ item.guildName }}
                </div>
                <div class="team-join-list-ul-li-text">{{ item.id }}</div>
                <div
                  class="team-join-list-ul-li-text"
                  :class="
                    item.applyStatus == '-1' || item.applyStatus == '2'
                      ? 'applyfor'
                      : ''
                  "
                  @click="applyStatusButton(item)"
                >
                  {{
                    item.applyStatus == "-1"
                      ? "申请"
                      : item.applyStatus == "1"
                      ? "申请中"
                      : item.applyStatus == "2"
                      ? "申请"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 创建 -->
        <div class="team-addteam" v-if="establishShow">
          <div class="team-addteam-input">
            <div class="team-addteam-input-tilte">战队名称</div>
            <el-input v-model="name" placeholder="请输入战队名"></el-input>
          </div>
          <div class="team-addteam-input-tilte">头像</div>
          <div class="flex" style="justify-content: center">
            <div
              v-for="(item, index) in teamImgList"
              :key="index"
              class="team-addteam-box"
              :class="teamImgIndex == index ? 'imgwert' : ''"
              @click="teamAvatarButton(item, index)"
            >
              <img :src="item.img" alt="" class="team-addteam-img" />
            </div>
          </div>
          <!-- <div class="team-addteam-img">
            <img
              :src="teamImg"
              alt=""
              style="width: 100%; height: 100%"
              @click="changetheprofilepicture()"
            />
          </div> -->

          <div class="team-addteam-button">
            <el-button
              type="success"
              :disabled="isdisabled"
              @click="modifyingaUserName()"
              >申请</el-button
            >
          </div>
        </div>
      </template>

      <!-- 查看成员 -->
      <template v-else>
        <div class="team-viewMember">
          <div class="team-viewMember-message flex items-center">
            <div class="flex items-center">
              <img
                :src="teamList[0].guildImg"
                alt=""
                class="team-viewMember-message-img"
              />
              <div class="" style="height: 100%">
                <div class="team-viewMember-message-text">
                  {{ teamList[0].guildName }}
                </div>
                <div class="team-viewMember-message-text1">
                  战队编号：{{ teamList[0].id }}
                </div>
              </div>
            </div>
            <div
              class="team-viewMember-btewe flex"
              @click="applicationListButton()"
            >
              申请列表
              <img
                src="https://cdn.bubbleplan.cn/static/user/Vector6772x.png"
                alt=""
                class="team-viewMember-btewe-img"
              />
            </div>
          </div>
          <div class="team-viewMember-type">
            <div class="team-viewMember-type-border">
              <div class="flex team-viewMember-type-border-but">
                <div style="width: 30%; text-align: center">战队成员</div>
                <div style="width: 17.5%; text-align: center">成员名称</div>
                <div style="width: 17.5%; text-align: center">等级</div>
                <div style="width: 17.5%; text-align: center">战力值</div>
                <div style="width: 17.5%; text-align: center"></div>
              </div>
              <div class="team-viewMember-type-border-ul">
                <div
                  v-for="(item, index) in memberOblist"
                  :key="index"
                  class="team-viewMember-type-border-ul-li flex"
                >
                  <div class="team-viewMember-type-border-ul-li-text">
                    UID: {{ item.guildUser.userId }}
                  </div>
                  <div class="team-viewMember-type-border-ul-li-text">
                    {{ item.name }}
                  </div>
                  <div class="team-viewMember-type-border-ul-li-text">
                    {{ item.grade ? item.grade : 0 }}
                  </div>
                  <div class="team-viewMember-type-border-ul-li-text">
                    {{ item.power ? item.power : 0 }}
                  </div>
                  <div class="team-viewMember-type-border-ul-li-text">
                    {{ item.guildUser.level == 0 ? "队长" : "队员" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="team-viewMember-button">
            <div
              class="flex flex-evenly"
              v-if="memberOblist[0].guildUser.userId != userInfo.userId"
            >
              <div class="team-viewMember-button-text1" @click="quittheTeam()">
                退出战队
              </div>
            </div>
            <div class="flex flex-evenly" v-else>
              <div class="team-viewMember-button-text" @click="dissolve()">
                解散战队
              </div>
              <div class="team-viewMember-button-text" @click="upgrade()">
                升级战队
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <el-dialog
      title=""
      :visible.sync="applicationteamShow"
      width="20%"
      :show-close="false"
    >
      <div class="applicationteamClass">
        <div class="applicationteamClass-title">申请战队</div>
        <img
          src="https://cdn.bubbleplan.cn/static/user/X2x.png"
          alt=""
          class="applicationteamClass-img"
          @click="applicationteamShow = false"
        />
        <div class="applicationteamClass-num flex flex-center">
          <img
            src="https://cdn.bubbleplan.cn/static/journalism/chip.png"
            alt=""
            class="applicationteamClass-num-img"
          />
          <div class="applicationteamClass-num-text">×10</div>
        </div>
        <div
          class="applicationteamClass-team_cc"
          @click="confirmtheapplication()"
        >
          确认申请
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="applicationListShow"
      width="45%"
      :show-close="false"
    >
      <div class="applicationListClass" @scroll="handleScroll1">
        <img
          src="https://cdn.bubbleplan.cn/static/user/X2x.png"
          alt=""
          class="applicationListClass-back"
          @click="applicationListShow = !applicationListShow"
        />
        <div class="applicationListClass-li">
          <div class="flex applicationListClass-li-type">
            <div style="width: 10%"></div>
            <div style="width: 30%; text-align: center">战队成员</div>
            <div style="width: 20%; text-align: center">成员名称</div>
            <div style="width: 20%; text-align: center">等级</div>
            <div style="width: 20%; text-align: center">战力值</div>
          </div>
          <div class="applicationListClass-li-ul">
            <div
              v-for="(item, index) in applyforList"
              :key="index"
              class="applicationListClass-li-ul-li flex"
            >
              <div style="width: 10%" class="flex items-center flex-center">
                <img
                  :src="
                    !item.isState
                      ? 'https://cdn.bubbleplan.cn/static/user/Group102162x.png'
                      : 'https://cdn.bubbleplan.cn/static/user/Group102162x1.png'
                  "
                  alt=""
                  class="applicationListClass-li-ul-li-img"
                  @click="selectButton(item, index)"
                />
              </div>
              <div class="applicationListClass-li-ul-li-text">
                UID: {{ item.guildUser.userId }}
              </div>

              <div class="applicationListClass-li-ul-li-text">
                {{ item.name }}
              </div>
              <div class="applicationListClass-li-ul-li-text">
                {{ item.grade ? item.grade : 0 }}
              </div>
              <div class="applicationListClass-li-ul-li-text">
                {{ item.power ? item.power : 0 }}
              </div>
            </div>
          </div>
        </div>
        <div class="applicationListClass-li-button flex flex-evenly">
          <div
            class="applicationListClass-li-button-refuse"
            @click="applyrefuseStatusButton(2)"
          >
            拒绝
          </div>
          <div
            class="applicationListClass-li-button-consent"
            @click="applyrefuseStatusButton(0)"
          >
            同意
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="titleShow"
      width="30%"
      :show-close="false"
    >
      <div class="titleShowClass">
        <img
          src="https://cdn.bubbleplan.cn/static/user/X2x.png"
          alt=""
          class="titleShowClass-img"
          @click="titleShow = false"
        />
        <div class="titleShowClass-title">{{ titleShowTitle }}</div>
        <div class="titleShowClass-text">{{ titleShowText }}</div>
        <div class="titleShowClass-button flex flex-evenly">
          <div class="titleShowClass-button-left" @click="leftButton()">
            {{ titleShowLeft }}
          </div>
          <div class="titleShowClass-button-right" @click="rightButton()">
            {{ titleShowRight }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="战队头像" :visible.sync="teamImgShow" width="30%">
      <div class="teamImgShow">
        <div class="teamImgShow-text flex">
          <div
            v-for="(item, index) in teamImgList"
            :key="index"
            @click="teamImgIndex = index"
          >
            <img
              :src="item.img"
              alt=""
              class="teamImgShow-img"
              :class="teamImgIndex == index ? 'teamImgShow-border' : ''"
            />
          </div>
        </div>
        <div class="teamImgShow-button" @click="teamImgButton()">确认</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: Object,
  },
  data() {
    return {
      createorapply: null,
      teamImgShow: false,
      teamImgIndex: null,
      teamImg: "https://cdn.bubbleplan.cn/static/team/team_img.png",
      teamImgList: [
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/12341235.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/123123.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/13123123.png",
        },
      ],
      queryId: "",
      isdisabled: true,
      name: "",
      selectShow: true,
      JointheteamShow: false,
      establishShow: false,
      applicationteamShow: false,
      applicationListShow: false,
      titleShow: false,
      titleShowTitle: "解散战队",
      titleShowText: "是否解散该战队？",
      titleShowLeft: "是",
      titleShowRight: "否",
      memberOblist: [],
      teamList: [],
      titleShowType: 0, //0解散 1申请加入
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      applyforList: [],
      hasNextPage: false,
      teamId: null,
      ids: [],
      agreeorRefuseType: 0,
    };
  },
  watch: {
    name(newValue) {
      if (newValue) {
        this.isdisabled = false;
      } else {
        this.isdisabled = true;
      }
    },
  },
  methods: {
    teamAvatarButton(item, index) {
      this.teamImgIndex = index;
    },
    teamImgButton() {
      this.teamImg = this.teamImgList[this.teamImgIndex].img;
      this.teamImgShow = false;
    },
    changetheprofilepicture() {
      this.teamImgIndex = null;
      this.teamImgShow = true;
    },
    //选择
    selectButton(item, index) {
      this.applyforList[index].isState = !this.applyforList[index].isState;
    },
    //申请加入
    applyStatusButton(item) {
      if (item.applyStatus == "1") return;
      this.titleShowType = 1;
      this.titleShow = true;
      this.titleShowTitle = "申请加入";
      this.titleShowText = "是否申请加入该战队？";
      this.titleShowLeft = "否";
      this.titleShowRight = "是";
      this.teamId = item.id;
    },
    //申请列表触底
    handleScroll1(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.applyList("bottom");
        }
      }
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("已滚动到底部");
        // 在这里执行你想要的操作
      }
    },

    Joinorapply1() {
      if (this.createorapply == null) {
        return;
      }
      this.selectShow = false;
      if (this.createorapply == 0) {
        this.queryGuildById();
        this.JointheteamShow = true;
      } else {
        this.establishShow = true;
      }
      this.createorapply = null;
    },
    //申请 or 加入
    Joinorapply(type) {
      this.createorapply = type;
    },
    //查询战队列表
    async queryGuildById() {
      const { data, code } = await this.$ajax.queryGuildById({
        queryId: this.queryId,
      });
      if (code == 200) {
        this.teamList = data;
      }
    },

    //申请
    modifyingaUserName() {
      if (this.teamImgIndex == null) {
        return;
      }
      this.applicationteamShow = true;
    },
    // 确认申请
    async confirmtheapplication() {
      this.applicationteamShow = false;
      const params = {
        img: this.teamImgList[this.teamImgIndex].img,
        name: this.name,
      };
      const { data, code } = await this.$ajax.createGuild(params);
      if (code == 200) {
        this.$emit("getUserInfo");
        this.name = "";
        this.teamImgIndex = null;
        this.memberList();
      }
    },
    //获取成员列表
    async memberList() {
      const { data, code } = await this.$ajax.memberList();
      if (code == 200) {
        this.memberOblist = data;
        setTimeout(() => {
          this.queryId = this.memberOblist[0].guildUser.guildId;
          this.queryGuildById();
        }, 10);
      }
    },
    //申请列表
    async applyList(type) {
      const params = {
        ...this.page,
      };
      const { data, code } = await this.$ajax.applyList(params);
      data.list.forEach((item, index) => {
        data.list[index] = {
          ...item,
          isState: false,
        };
      });
      if (type == "bottom") {
        this.applyforList.push(...data.list);
      } else {
        this.applyforList = data.list;
      }
      this.hasNextPage = true;
    },
    //解散战队
    async disband() {
      const { data, code } = await this.$ajax.disband();
      this.selectShow = true;
      this.establishShow = false;
      this.$emit("getUserInfo");
    },
    //退出战队
    async guildExit() {
      const { data, code } = await this.$ajax.guildExit({
        guildId: this.teamList[0].id,
      });
      if (code == 200) {
        this.selectShow = true;
        this.establishShow = false;
        this.queryId = "";
        this.$emit("getUserInfo");
      }
    },
    //同意or 拒绝
    async agreeOrRefuse() {
      console.log(this.ids);
      const params = {
        ids: this.ids,
        isAgree: this.agreeorRefuseType,
      };
      const { data, code } = await this.$ajax.agreeOrRefuse(params);
      if (code == 200) {
        this.page.pageNum = 1;
        this.applyList();
        this.memberList();
      }
      this.ids = [];
    },
    //申请加入
    async apply() {
      const { data, code } = await this.$ajax.apply({
        guildId: this.teamId,
      });
      this.teamId = null;
      if (code == 200) {
        this.queryGuildById();
      }
    },
    //解散
    dissolve() {
      this.titleShowType = 0;
      this.titleShow = true;
      this.titleShowTitle = "解散战队";
      this.titleShowText = "是否解散该战队？";
      this.titleShowLeft = "是";
      this.titleShowRight = "否";
    },
    //退出
    quittheTeam() {
      this.titleShowType = 3;
      this.titleShow = true;
      this.titleShowTitle = "退出战队";
      this.titleShowText = "是否退出该战队？";
      this.titleShowLeft = "是";
      this.titleShowRight = "否";
    },
    //升级
    upgrade() {
      this.$message("开发中");
    },
    //弹窗右边按钮
    rightButton() {
      if (this.titleShowType == 0) {
        this.titleShow = false;
      } else if (this.titleShowType == 1) {
        this.titleShow = false;
        this.apply();
      } else if (this.titleShowType == 2) {
        this.titleShow = false;
        this.agreeOrRefuse();
      } else if (this.titleShowType == 3) {
        this.titleShow = false;
      }
    },
    //弹窗左边按钮
    leftButton() {
      if (this.titleShowType == 0) {
        this.disband();
        this.titleShow = false;
      } else if (this.titleShowType == 1) {
        this.titleShow = false;
      } else if (this.titleShowType == 2) {
        this.titleShow = false;
      } else {
        this.titleShowType == 3;
        this.titleShow = false;
        this.guildExit();
      }
    },
    //查看申请列表
    applicationListButton() {
      this.applyList();
      this.applicationListShow = true;
    },
    //拒绝申请or  同意申请
    async applyrefuseStatusButton(type) {
      this.ids = [];
      this.applyforList.forEach((item) => {
        if (item.isState) {
          this.ids.push(item.guildUser.id);
        }
      });
      if (this.ids.length == 0) {
        this.$message("至少选择一个用户");
        return;
      }
      this.agreeorRefuseType = type;
      if (type == 2) {
        this.titleShowType = 2;
        this.titleShow = true;
        this.titleShowTitle = "拒绝申请";
        this.titleShowText = "是否拒绝当前选择用户加入该战队？";
        this.titleShowLeft = "否";
        this.titleShowRight = "是";
      } else {
        this.titleShowType = 2;
        this.titleShow = true;
        this.titleShowTitle = "加入申请";
        this.titleShowText = "是否同意当前选择用户加入该战队？";
        this.titleShowLeft = "否";
        this.titleShowRight = "是";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  padding: 50px 56px;
  &-pagw {
    width: 100%;
    height: 610px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 79px 49px;
  }
  &-applyorcreate {
    &-button {
      width: 100%;
      margin-top: 178px;
      display: flex;

      :deep(.el-button) {
        margin: auto;
        width: 144px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
          no-repeat;
        background-size: 100% 100%;
        border: none;
        margin: auto;
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-weight: bold;
        text-shadow: -3px -3px #000, 3px -3px #000, -3px 3px #000, 3px 3px #000;
        /* 模拟描边效果 */
        font-size: 26px;
        line-height: 44px;
      }
    }
    &-li {
      justify-content: center;
    }
    &-bgc:hover {
      cursor: pointer;
    }
    &-bgc1:hover {
      cursor: pointer;
    }
    &-bgc {
      width: 223px;
      height: 310px;
      background: url("https://cdn.bubbleplan.cn/static/user/Group102632x.png")
        no-repeat;
      background-size: 100% 100%;
      padding-top: 101px;
      margin-right: 26px;
      &-border {
        border: 4px solid #533690;
        border-radius: 15.74px;
      }
    }
    &-bgc1 {
      width: 223px;
      height: 310px;
      background: url("https://cdn.bubbleplan.cn/static/user/Group102642x.png")
        no-repeat;
      background-size: 100% 100%;
      padding-top: 101px;
    }
    &-text {
      margin-left: 195px;
      color: #ffffff;
      // -webkit-text-stroke-width: 2;
      // -webkit-text-stroke-color: #000;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;
      /* 模拟描边效果 */
      font-family: "Nishiki-teki";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 39.67px;
    }
    // &-button {
    //   width: 134px;
    //   height: 43px;
    //   flex-shrink: 0;
    //   border-radius: 50px;
    //   border: 2px solid #000;
    //   background: #d6fb00;
    //   color: #000000;
    //   text-align: center;
    //   font-size: 22px;
    //   line-height: 43px;
    //   margin-top: 10px;
    //   margin-left: 195px;
    // }
    // &-button:hover {
    //   cursor: pointer;
    // }
  }
  &-join {
    &-top {
      width: 100%;
      height: 190px;
      background: url("https://cdn.bubbleplan.cn/static/user/Vector5472x.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 0 85px;
      padding-top: 25px;
    }
    &-title {
      color: #fef5de;
      font-size: 24px;
      font-weight: 700;
      text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
        2px 2px 0 #341030;
      /* 模拟描边效果 */
      font-family: "TsangerYuMo";
      text-align: center;
    }
    &-input {
      margin-top: 13px;
      // :deep(.el-input) {
      //   height: 68px;
      //   border-radius: 10px;
      //   background: #021521;
      //   width: 70%;
      // }
      :deep(.el-input--small .el-input__inner) {
        width: 100%;
        height: 63px;
        background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
          no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        border: none;
        font-size: 23px;
        color: #d9d2e4;
        text-align: center;
        font-family: "TsangerYuMo";
        font-weight: bold;
      }
      &-button {
        margin: auto;
        width: 144px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
          no-repeat;
        background-size: 100% 100%;
        border: none;
        margin: auto;
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-weight: bold;
        text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
          2px 2px 0 #533690;
        /* 模拟描边效果 */
        font-size: 26px;
        line-height: 54px;
        text-align: center;
        margin-top: 9px;
      }
      &-button:hover {
        cursor: pointer;
      }
    }

    &-list {
      width: 100%;
      height: 445px;
      margin-top: 27px;
      padding: 15px 87px;
      background: url("https://cdn.bubbleplan.cn/static/user/Vector5472x.png")
        no-repeat;
      background-size: 100% 100%;
      &-type {
        width: 100%;
        height: 59px;
        background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 59px;
        &-title {
          color: #fff;
          font-size: 18px;
          text-align: center;
        }
      }
      &-ul::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &-ul {
        height: 400px;
        overflow: auto;
        &-li {
          height: 59px;
          color: #6d689a;
          text-align: center;
          font-size: 18px;
          font-weight: Bold;
          line-height: 59px;
          &-text {
            // font-size: 18px;
            // padding: 15px 0;
            width: 33%;
            // text-align: center;
            // color: rgb(100, 132, 154);
          }
        }
        &-li:nth-child(2n) {
          width: 100%;
          height: 59px;
          background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
            no-repeat;
          background-size: 100% 100%;
          line-height: 59px;

          color: #d9d2e4;
          text-align: center;
          font-size: 18px;
          font-weight: Bold;
        }
      }
    }
  }
  &-addteam {
    width: 100%;
    height: 631px;
    padding: 15px 87px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 25px 97px;
    &-box {
      width: 165px;
      height: 165px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .imgwert {
      background: url("https://cdn.bubbleplan.cn/m_bubble/mine/Frame96352x.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &-img {
      width: 104px;
      height: 104px;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-border {
      margin-top: 20px;
      width: 100%;
      height: 4px;
      background: #021521;
    }
    &-input {
      margin-bottom: 30px;
      &-tilte {
        color: #fef5de;
        font-size: 24px;
        font-weight: 700;
        text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
          2px 2px 0 #341030;
        /* 模拟描边效果 */
        font-family: "TsangerYuMo";
        text-align: center;
      }

      :deep(.el-input--small .el-input__inner) {
        width: 100%;
        height: 63px;
        background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
          no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        border: none;
        font-size: 23px;
        color: #d9d2e4;
        text-align: center;
        font-family: "TsangerYuMo";
        font-weight: bold;
        margin-top: 13px;
      }
    }
    &-button {
      width: 100%;
      margin-top: 218px;
      display: flex;
    }
    :deep(.el-button) {
      margin: auto;
      width: 144px;
      height: 61px;
      background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      margin: auto;
      color: #f1eae2;
      font-family: "TsangerYuMo";
      font-weight: bold;
      text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
        2px 2px 0 #533690;
      /* 模拟描边效果 */
      font-size: 26px;
      line-height: 44px;
    }

    :deep(.el-button--success.is-disabled) {
      width: 144px;
      height: 61px;
      background: url("https://cdn.bubbleplan.cn/static/user/Frame96192x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      margin: auto;
      color: #3d2f52;
      font-size: 26px;
      font-family: "TsangerYuMo";
      font-weight: bold;
      text-shadow: -2px -2px 0 #b2abdf, 2px -2px 0 #b2abdf, -2px 2px 0 #b2abdf,
        2px 2px 0 #b2abdf;
      /* 模拟描边效果 */
    }
  }
  .applicationteamClass {
    width: 497px;
    height: 276px;
    background: url("https://cdn.bubbleplan.cn/static/user/Group102362x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 20px 0;
    position: relative;
    &-title {
      color: #574352;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 25px;
      font-weight: bold;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 49px;
      height: 49px;
      position: absolute;
      top: 0;
      right: -12px;
    }
    &-num {
      margin-top: 48px;
      position: relative;
      &-img {
        width: 95px;
        height: 76px;
      }
      &-text {
        position: absolute;
        // width: 43px;
        // height: 27px;
        color: #ffffff;
        text-align: center;
        text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000;
        /* 模拟描边效果 */
        font-family: "Klipan Black";
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        bottom: -10px;
        right: 38%;
      }
    }
    &-team_cc {
      margin: auto;
      margin-top: 46.78px;
      width: 148px;
      height: 54px;
      background: url("https://cdn.bubbleplan.cn/static/user/Ve131ctor6062x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 54px;
      font-family: "TsangerYuMo";
      font-weight: bold;
      color: #f3e9d0;
      text-align: center;
      font-size: 22px;
      text-align: center;
    }
    &-team_cc:hover {
      cursor: pointer;
    }
  }
  &-viewMember {
    // padding: 39px 30px;
    &-btewe:hover {
      cursor: pointer;
    }
    &-btewe {
      color: #d9d9d9;
      font-size: 18px;
      align-items: center;
      &-img {
        margin-left: 7px;
        width: 10px;
        height: 13px;
      }
    }
    &-message {
      width: 461px;
      height: 133px;
      background: url("https://cdn.bubbleplan.cn/static/user/Group103872x.png")
        no-repeat;
      background-size: 100% 100%;
      justify-content: space-evenly;
      &-img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-left: -44px;
      }
      &-text {
        color: #fef5de;
        font-size: 34px;
        font-family: "TsangerYuMo";
        font-weight: bold;
        text-shadow: -4px -4px 0 #533690, 4px -4px 0 #533690, -4px 4px 0 #533690,
          4px 4px 0 #533690;
        /* 模拟描边效果 */
        margin-top: -8px;
      }
      &-text1 {
        color: #fbdfb4;
        font-size: 20px;
        margin-top: 8px;
      }
    }
    &-border {
      margin-top: 20px;
      width: 100%;
      height: 4px;
      background: #021521;
    }
    &-type {
      margin-top: 20px;
      width: 100%;
      height: 478px;
      background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 24px 43px;
      &-border {
        width: 100%;
        &-but {
          width: 100%;
          height: 59px;
          line-height: 59px;
          background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
            no-repeat;
          background-size: 100% 100%;
          color: #d9d2e4;
          font-size: 18px;
          font-weight: Bold;
        }
        &-ul::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        &-ul {
          height: 320px;
          overflow: auto;
          &-li {
            width: 100%;
            height: 59px;
            line-height: 59px;
            color: #6d689a;
            font-size: 18px;
            font-weight: Bold;
            text-align: center;
            &-text {
              // color: #64849a;
              // font-size: 18px;
              // text-align: center;
              width: 17.5%;
            }
            &-text:nth-child(1) {
              width: 30%;
            }
          }
          &li:nth-child(2n) {
            width: 100%;
            height: 59px;
            line-height: 59px;
            background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
              no-repeat;
            background-size: 100% 100%;
            color: #d9d2e4;
            font-size: 18px;
            font-weight: Bold;
            text-align: center;
          }
        }
      }
    }
    &-button {
      margin-top: 0px;
      &-text {
        width: 193px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/1313Frame6192x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 61px;
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-size: 26px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
          2px 2px 0 #533690;
        /* 模拟描边效果 */
        text-align: center;
      }
      &-text:nth-child(2) {
        width: 193px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/Fra12312me96182x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 61px;
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-size: 26px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
          2px 2px 0 #533690;
        /* 模拟描边效果 */
        text-align: center;
      }
      &-text1:hover {
        cursor: pointer;
      }
      &-text1 {
        width: 193px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/Fra12312me96182x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 61px;
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-size: 26px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
          2px 2px 0 #533690;
        /* 模拟描边效果 */
        text-align: center;
      }
      &-text:hover {
        cursor: pointer;
      }
    }
  }
  .applyfor:hover {
    cursor: pointer;
  }
  .applicationListClass {
    width: 854px;
    height: 493px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 24px 43px;
    position: relative;
    &-back:hover {
      cursor: pointer;
    }
    &-back {
      position: absolute;
      right: -15px;
      top: -16px;
      width: 48px;
      height: 48px;
    }
    &-li {
      width: 100%;
      &-type {
        width: 100%;
        height: 59px;
        line-height: 59px;
        background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
          no-repeat;
        background-size: 100% 100%;
        color: #d9d2e4;
        font-size: 18px;
        font-weight: Bold;
      }
      &-ul::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &-ul {
        height: 320px;
        overflow: auto;
        line-height: 50px;
        &-li {
          line-height: 59px;
          &-img {
            width: 26px;
            height: 26px;
          }
          &-img:hover {
            cursor: pointer;
          }
          &-text {
            color: #64849a;
            font-size: 18px;
            text-align: center;
            width: 20%;
          }
          &-text:nth-child(2) {
            width: 30%;
          }
        }
        &-li:nth-child(2n) {
          width: 100%;
          height: 59px;
          line-height: 59px;
          background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
            no-repeat;
          background-size: 100% 100%;
          color: #d9d2e4;
          font-size: 18px;
          font-weight: Bold;
          text-align: center;
        }
      }
      &-button {
        margin-top: 50px;
        &-refuse {
          width: 140px;
          height: 61px;
          line-height: 61px;
          background: url("https://cdn.bubbleplan.cn/static/user/1313424312.png")
            no-repeat;
          background-size: 100% 100%;
          color: #f1eae2;
          font-family: "TsangerYuMo";
          font-size: 26px;
          font-weight: bold;
          text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690,
            -2px 2px 0 #533690, 2px 2px 0 #533690;
          /* 模拟描边效果 */
          text-align: center;
        }
        &-refuse:hover {
          cursor: pointer;
        }
        &-consent:hover {
          cursor: pointer;
        }
        &-consent {
          width: 140px;
          height: 61px;
          line-height: 61px;
          background: url("https://cdn.bubbleplan.cn/static/user/76546353.png")
            no-repeat;
          background-size: 100% 100%;
          color: #f1eae2;
          font-family: "TsangerYuMo";
          font-size: 26px;
          font-weight: bold;
          text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690,
            -2px 2px 0 #533690, 2px 2px 0 #533690;
          /* 模拟描边效果 */
          text-align: center;
        }
      }
    }
  }
  .titleShowClass {
    width: 488px;
    height: 271px;
    background: url("https://cdn.bubbleplan.cn/static/user/Group102362x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 20px 0;
    position: relative;
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 49px;
      height: 49px;
      position: absolute;
      top: 0;
      right: -12px;
    }
    &-title {
      color: #574352;
      text-align: center;
      font-family: "TsangerYuMo";
      font-size: 25px;
      font-weight: bold;
    }
    &-text {
      color: #574352;
      font-size: 24px;
      text-align: center;
      margin-top: 75.3px;
    }
    &-button {
      margin-top: 72px;
      &-left:hover {
        cursor: pointer;
      }
      &-right:hover {
        cursor: pointer;
      }
      &-left {
        width: 141px;
        height: 56px;
        background: url("https://cdn.bubbleplan.cn/static/user/Grou123123p102072x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 56px;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000,
          2px 2px 0 #000;
        /* 模拟描边效果 */
        color: #fff;
      }
      &-right {
        width: 141px;
        height: 56px;
        background: url("https://cdn.bubbleplan.cn/static/user/Gro123123up102062x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 56px;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        text-shadow: -3px -3px 0 #000, 3px -3px 0 #000, -3px 3px 0 #000,
          2px 2px 0 #000;
        /* 模拟描边效果 */
        color: #fff;
      }
    }
  }
  .teamImgShow {
    &-text {
      padding: 0 80px;
      justify-content: space-around;
    }
    &-button {
      margin: auto;
      margin-top: 39px;
      width: 183.75px;
      height: 59px;
      border-radius: 6.73px;
      background: #daff00;
      color: #10061e;
      font-size: 22px;
      text-align: center;
      line-height: 59px;
    }
    &-button:hover {
      cursor: pointer;
    }
    &-border {
      border-radius: 50%;
      border: 3px solid rgba(4, 0, 0, 1);
    }
    &-img:hover {
      cursor: pointer;
    }
  }
  .teamImgShow-img {
    width: 100px;
    height: 100px;
  }
}
</style>