<template>
  <div class="securitycode">
    <div class="securitycode-pagw">
      <el-form
        :model="mailboxForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item label="邮箱地址" prop="oldMail">
          <el-input v-model="mailboxForm.oldMail" disabled></el-input>
        </el-form-item>
        <el-form-item
          label="邮箱验证码"
          prop="oldVerificationCode"
          style="margin-top: 40px"
        >
          <div class="flex">
            <el-input
              v-model="mailboxForm.oldVerificationCode"
              placeholder="请输入验证码"
            ></el-input>
            <el-button type="success" @click="getcode()" v-if="isgetcode">{{
              text
            }}</el-button>
            <!-- <el-button type="success" disabled v-else>{{ countdown
                          }}秒</el-button> -->
            <div class="buttonwerw" v-else>{{ countdown }}秒</div>
          </div>
        </el-form-item>
        <el-form-item
          label="安全码"
          prop="payPassword"
          style="margin-top: 40px"
        >
          <el-input
            v-model="mailboxForm.payPassword"
            placeholder="请输入安全码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="securitycode-name-button">
        <el-button type="success" :disabled="isdisabled" @click="storage()"
          >保存</el-button
        >
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="base64ImageShow"
      width="30%"
      @close="closeBase64"
      :show-close="false"
    >
      <div class="base64ImageClass">
        <div class="flex">
          <el-input v-model="validate" placeholder="请输入验证码">
            <div
              slot="prefix"
              style="height: 100%; display: flex; align-items: center"
            >
              <img
                src="https://cdn.bubbleplan.cn/static/login/Frame2x.png"
                alt=""
                style="width: 29px; height: 29px"
                class="register-input-imgew"
              />
            </div>
          </el-input>
          <div class="flex" style="margin-left: 10px; align-items: center">
            <img
              :src="base64Image"
              alt="Base64 Image"
              class="base64ImageClass-img"
            />
            <img
              src="https://cdn.bubbleplan.cn/static/home/refresh.png"
              alt=""
              style="width: 28px; height: 28px; margin-left: 10px"
              class="base64ImageClass-refresh"
              @click="getImgBase64(1)"
            />
          </div>
        </div>
        <div
          style="margin-top: 43px; justify-content: space-evenly"
          class="flex"
        >
          <div class="base64ImageClass-button" @click="closeBase64()">取消</div>
          <div @click="countersign()" class="base64ImageClass-button">确认</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
export default {
  props: {
    userInfo: Object,
  },
  data() {
    return {
      mailboxForm: {
        oldMail: JSON.parse(localStorage.getItem("user_info")).mail,
      },
      text: "获取",
      countdown: 60,
      isgetcode: true,
      timerInterval: null,
      rules: {},
      isdisabled: false,
      text1: "获取",
      countdown1: 60,
      isgetcode1: true,
      timerInterval1: null,
      rules: {
        oldVerificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        payPassword: [
          { required: true, message: "请输入安全码", trigger: "blur" },
        ],
      },
      base64Image: null,
      base64ImageShow: false,
      captchaKey: null,
      validate: "",
      sendMailIndex: 0,
    };
  },
  watch: {
    mailboxForm: {
      handler: function (newValue, oldVal) {
        if (newValue.payPassword && newValue.oldVerificationCode) {
          this.isdisabled = false;
        } else {
          this.isdisabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    //获取图片验证码
    async getImgBase64(type) {
      const params = {
        key: this.mail,
      };
      const { data, code } = await this.$ajax.getImgBase64(params);
      if (code == 200) {
        this.base64Image = data.base64Image;
        this.captchaKey = data.captchaKey;
        if (type != 1) {
          this.base64ImageShow = true;
        }
      }
    },
    closeBase64() {
      this.base64ImageShow = false;
      this.validate = "";
    },
    countersign() {
      this.countdown = 60;
      this.sendMail();
    },
    //更换
    async storage() {
      if (this.mailboxForm.payPassword.length != 6) {
        this.$message.error("安全码仅限6位");
        return;
      }
      const { data, code } = await this.$ajax.setPayPassword({
        payPassword: this.mailboxForm.payPassword,
        verificationCode: this.mailboxForm.oldVerificationCode,
      });
      if (code == 200) {
        // this.getUserInfo()
        this.mailboxForm = {
          oldMail: JSON.parse(localStorage.getItem("user_info")).mail,
        };
      }
    },
    //获取用户信息
    async getUserInfo() {
      const { data } = await this.$ajax.getUserInfo();
      localStorage.setItem("user_info", JSON.stringify(data));
      this.userInfo = data;
    },
    //发送验证码
    async sendMail() {
      let params = {
        key: this.captchaKey,
        validate: this.validate,
      };
      if (this.sendMailIndex == 0) {
        params.mail = this.mailboxForm.oldMail;
      } else {
        params.mail = this.mailboxForm.newMail;
      }
      const { data, code } = await this.$ajax.sendMail(params);
      if (code == 200) {
        if (this.sendMailIndex == 0) {
          this.timerInterval = setInterval(this.updateTimer, 1000);
          this.isgetcode = false;
        } else {
          this.timerInterval = setInterval(this.updateTimer1, 1000);
          this.isgetcode1 = false;
        }
        this.base64ImageShow = false;
      }
      this.validate = "";
    },

    getcode() {
      if (!this.$reg.email1.test(this.mailboxForm.oldMail)) {
        this.$message.error("请输入正确邮箱地址");
        return;
      }
      this.sendMailIndex = 0;
      this.getImgBase64();
      return;
      this.countdown = 60;
      this.sendMail(0);
    },
    getcode1() {
      this.sendMailIndex = 1;
      this.getImgBase64();
      return;
      this.countdown1 = 60;
      this.sendMail(1);
    },
    updateTimer1() {
      if (this.countdown1 == 0) {
        clearInterval(this.timerInterval1);
        // 验证码过期的逻辑
        this.text1 = "获取";
        this.isgetcode1 = true;
      } else {
        this.countdown1--;
      }
    },
    updateTimer() {
      if (this.countdown == 0) {
        clearInterval(this.timerInterval);
        // 验证码过期的逻辑
        this.text = "获取";
        this.isgetcode = true;
      } else {
        this.countdown--;
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.securitycode {
  padding: 50px 56px;
  &-pagw {
    width: 100%;
    height: 631px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 25px 93px;
    .buttonwerw {
      width: 151px;
      height: 69px;
      background: url("https://cdn.bubbleplan.cn/static/login/Frame96192x.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 69px;
      margin-left: 18px;
      color: #f1eae2;
      font-size: 20px;
      font-weight: Bold;
      border: none;
    }
  }
  :deep(.el-input.is-disabled .el-input__inner) {
    width: 100%;
    height: 63px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
      no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
    font-size: 23px;
    color: #d9d2e4;
    text-align: center;
    font-family: "TsangerYuMo";
    font-weight: bold;
  }

  :deep(.el-form-item__error) {
    left: 46%;
    top: 110%;
  }
  :deep(.el-input__inner) {
    width: 100%;
    height: 63px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
      no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    border: none;
    font-size: 23px;
    color: #d9d2e4;
    text-align: center;
    font-family: "TsangerYuMo";
    font-weight: bold;
  }

  // :deep(.el-input) {
  //   width: 375px;
  // }

  :deep(.el-form-item__label) {
    font-family: "TsangerYuMo";
    color: #fef5de;
    font-size: 24px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
      2px 2px 0 #341030;
    /* 模拟描边效果 */
  }

  :deep(.el-button) {
    margin-left: 13px;
    width: 149px;
    height: 72px;
    background: url("https://cdn.bubbleplan.cn/static/user/Frame9123126192x.png")
      no-repeat;
    background-size: 100% 100%;
    color: #f1eae2;
    text-align: center;
    line-height: 52px;
    font-size: 23px;
    font-weight: Bold;
    border: none;
  }

  .securitycode-name-button {
    width: 100%;
    margin-top: 32px;
    display: flex;
    :deep(.el-button) {
      margin: auto;
      width: 144px;
      height: 61px;
      background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      margin: auto;
      color: #f1eae2;
      font-family: "TsangerYuMo";
      font-weight: bold;
      text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
        2px 2px 0 #533690;
      /* 模拟描边效果 */
      font-size: 26px;
      line-height: 44px;
    }

    :deep(.el-button--success.is-disabled) {
      background: rgba(218, 255, 0, 0.4);
      border: none;
    }
  }
  .base64ImageClass {
    width: 484px;
    height: 198px;
    background: url("https://cdn.bubbleplan.cn/static/login/Group103612x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 46px 31px;

    // :deep(.el-input) {
    //   width: 280px;
    //   height: 58px;
    // }
    :deep(.el-input--small .el-input__inner) {
      width: 280px;
      height: 58px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector5602x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      color: #574352;
      font-size: 20px;
      font-weight: Bold;
      padding-left: 50px;
    }
    :deep(.el-input__inner)::placeholder {
      color: #574352;
      font-size: 20px;
      font-weight: Bold;
    }
    &-img {
      width: 107px;
      height: 48px;
    }
    &-button:nth-child(2) {
      width: 140px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vector6072x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 56px;
      color: #f3e9d0;
      font-family: "TsangerYuMo";
      font-size: 24px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      text-align: center;
    }
    &-button {
      width: 140px;
      height: 56px;
      background: url("https://cdn.bubbleplan.cn/static/login/Vec123tor6062x.png")
        no-repeat;
      background-size: 100% 100%;
      line-height: 56px;
      color: #f3e9d0;
      font-family: "TsangerYuMo";
      font-size: 24px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      text-align: center;
    }
    &-refresh:hover {
      cursor: pointer;
    }
    &-button:hover {
      cursor: pointer;
    }
  }
}
</style>