<template>
  <div class="myOrder">
    <div class="myOrder-pagw">
      <div class="myOrder-pagw-type flex">
        <div
          v-for="(item, index) in type"
          :key="index"
          :class="
            typeIndex == index
              ? 'myOrder-pagw-type-li'
              : 'myOrder-pagw-type-li1'
          "
          @click="tpyeButton(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="myOrder-pagw-list flex" @scroll="handleScroll">
        <div
          v-for="(item, index) in orderList"
          :key="index"
          class="myOrder-pagw-list-li"
        >
          <div class="myOrder-pagw-list-li-top flex">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="myOrder-pagw-list-li-top-gswerq"
            >
              <el-image
                :src="item.productImg"
                alt=""
                class="myOrder-pagw-list-li-top-img"
              ></el-image>
            </div>
            <div class="myOrder-pagw-list-li-top-right flex">
              <div>{{ item.productName }}</div>
              <div style="font-weight: Black">￥{{ item.actualAmount }}</div>
            </div>
          </div>
          <div class="myOrder-pagw-list-li-border"></div>
          <div class="flex" style="justify-content: space-between">
            <div
              v-if="item.status == 1"
              class="myOrder-pagw-list-li-textw flex"
            >
              <div class="myOrder-pagw-list-li-textw-www">剩余</div>
              <el-statistic
                :value="countdown(item.createTime)"
                time-indices
                format="mm:ss"
                @finish="finish"
              >
              </el-statistic>
            </div>
            <div v-else class="myOrder-pagw-list-li-textw">
              {{ item.createTime }}
            </div>
            <div
              class="myOrder-pagw-list-li-button"
              @click="continuetopay(item)"
            >
              {{
                item.status == 1
                  ? "去支付"
                  : item.status == 2
                  ? "已付款"
                  : "已取消"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeIndex: 0,
      type: [
        {
          name: "全部",
        },
        {
          name: "待付款",
        },
        {
          name: "已付款",
        },
        {
          name: "已取消",
        },
      ],
      orderList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      hasNextPage: false,
    };
  },
  mounted() {
    this.typeIndex = 0;
    this.orderList = [];
    this.page.pageNum = 1;
    this.listOrders();
  },
  methods: {
    finish() {
      this.orderList = [];
      this.page.pageNum = 1;
      this.listOrders();
      // this.orderList[index].status = 3
    },
    countdown(createTime) {
      /*
       *创建时间 + 3分钟 >= 现在的时间
       *3 -  (现-创 )
       */
      const date = new Date(createTime).getTime();
      // const nowDate = new Date().getTime();
      const setTime = 3 * 62 * 1000;
      const timeLeft = setTime + date;
      if (timeLeft > 0) {
        return timeLeft;
      }
    },
    continuetopay(item) {
      if (item.status == 1) {
        if (item.payUrl) {
          location.href = item.payUrl;
        }
      }
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("已滚动到底部");
        // 在这里执行你想要的操作
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.listOrders("bottom");
        }
      }
    },
    tpyeButton(index) {
      if (this.typeIndex == index) return;
      this.typeIndex = index;
      this.orderList = [];
      this.page.pageNum = 1;
      this.listOrders();
    },
    //获取订单
    async listOrders(type) {
      const params = {
        ...this.page,
      };
      if (this.typeIndex != 0) {
        params.status = this.typeIndex;
      }
      const { data, code } = await this.$ajax.listOrder(params);
      if (code == 200) {
        if (type == "bottom") {
          this.orderList.push(...data.list);
        } else {
          this.orderList = data.list;
        }
        this.hasNextPage = data.hasNextPage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myOrder {
  padding: 50px 56px;
  .myOrder-pagw-list-li-top-gswerq {
    width: 91px;
    height: 91px;
  }
  &-pagw {
    width: 942px;
    height: 631px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 30px 33px;
    &-type {
      &-li {
        width: 102px;
        height: 55px;
        background: url("https://cdn.bubbleplan.cn/static/user/1231231231254242.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 55px;
        text-align: center;
        color: #2f2f2f;
        font-size: 20px;
        font-weight: Bold;
        margin-right: 20px;
      }
      &-li:hover {
        cursor: pointer;
      }
      &-li1:hover {
        cursor: pointer;
      }
      &-li1 {
        width: 102px;
        height: 55px;
        background: url("https://cdn.bubbleplan.cn/static/user/863453453.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 55px;
        text-align: center;
        color: #2f2f2f;
        font-size: 20px;
        font-weight: Bold;
        margin-right: 20px;
      }
    }
    &-list::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-list {
      flex-wrap: wrap;
      padding: 30px 0;
      height: 500px;
      overflow: auto;
      &-li:nth-child(2n) {
        margin-right: 0;
      }
      &-li {
        width: 420px;
        height: 195.14px;
        border-radius: 9px;
        background: #f3ece4;
        margin-bottom: 17.8px;
        margin-right: 17px;
        padding: 20px 15px;
        &-top {
          &-img {
            // width: 91px;
            height: 91px;
            border-radius: 8px;
            margin-right: 12.9px;
          }
          &-right {
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            color: #4d3283;
            font-size: 18px;
          }
        }
        &-border {
          width: 100%;
          height: 1px;
          background: rgba(0, 0, 0, 0.2);
          margin: 17px 0;
        }
        &-textw {
          color: #7f7199;
          font-size: 17px;
          &-www {
            width: 50px;
          }
          :deep(.el-statistic .con) {
            color: #f41414;
            font-size: 17px;
          }
        }
        &-button:hover {
          cursor: pointer;
        }
        &-button {
          width: 107px;
          height: 41px;
          background: url("https://cdn.bubbleplan.cn/static/user/Vector6062x.png")
            no-repeat;
          background-size: 100% 100%;
          color: rgb(243, 233, 208);
          text-align: center;
          line-height: 41px;
          text-shadow: 0 1.67px 0 rgba(0, 0, 0, 0.25);
          font-family: "TsangerYuMo";
          font-size: 18px;
          font-weight: 800;
        }
      }
    }
  }
}
</style>