<template>
  <div class="shippingAddress">
    <div class="shippingAddress-pagw">
      <template v-if="isAdd">
        <div class="shippingAddress-list" @scroll="handleScroll">
          <div v-for="(item, index) in ressList" :key="index" class="li">
            <div class="flex flex-between">
              <div style="color: #eae3f3; font-size: 22px">
                收货人姓名：{{ item.name }}
              </div>
              <div style="color: #eae3f3; font-size: 22px; font-weight: 700">
                联系电话：{{ item.mobile }}
              </div>
            </div>
            <div
              class="flex flex-between items-center"
              style="margin-top: 17px"
            >
              <div style="color: #d9d2e4; font-size: 20px">
                收货地址：{{ item.address }}
              </div>
              <div class="flex items-center">
                <div
                  class="flex items-center"
                  style="margin-right: 28px"
                  @click="redact(item)"
                >
                  <img
                    src="https://cdn.bubbleplan.cn/static/user/Group102532x.png"
                    alt=""
                    style="width: 28px; height: 28px; margin-right: 7px"
                  />
                  <span style="color: #d9d2e4; font-size: 19px">编辑</span>
                </div>
                <div class="flex items-center" @click="setIsCheck(item)">
                  <img
                    src="https://cdn.bubbleplan.cn/static/user/Group102162x1.png"
                    alt=""
                    style="width: 28px; height: 28px; margin-right: 7px"
                    v-if="item.isCheck == 1"
                  />
                  <img
                    src="https://cdn.bubbleplan.cn/static/user/Group102162x.png"
                    alt=""
                    style="width: 28px; height: 28px; margin-right: 7px"
                    v-else
                  />

                  <span
                    style="color: #d9d2e4; font-size: 19px; margin-left: 6px"
                    >默认地址</span
                  >
                </div>
                <div class="flex items-center" style="margin-left: 20px">
                  <img
                    src="https://cdn.bubbleplan.cn/static/user/Group102182x.png"
                    alt=""
                    style="width: 28px; height: 28px; margin-right: 7px"
                  />
                  <span
                    style="color: #d9d2e4; font-size: 19px"
                    @click="deleteaddress(item)"
                  >
                    删除
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="userInfo-name-button">
          <el-button type="success" @click="addLocation()">新增</el-button>
        </div>
      </template>
      <template v-else>
        <div class="shippingAddress-form">
          <el-form
            :model="addLocationForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item label="收货人姓名" prop="name">
              <el-input v-model="addLocationForm.name"></el-input>
            </el-form-item>
            <el-form-item
              label="联系电话"
              prop="mobile"
              style="margin-top: 40px"
            >
              <el-input v-model="addLocationForm.mobile"></el-input>
            </el-form-item>
            <el-form-item
              label="收货地址"
              prop="address"
              style="margin-top: 40px"
            >
              <el-input v-model="addLocationForm.address"></el-input>
            </el-form-item>
          </el-form>
          <div class="userInfo-name-button button">
            <el-button type="success" :disabled="isdisabled" @click="insert()"
              >保存</el-button
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isAdd: true,
      addLocationForm: {
        name: "",
        mobile: "",
        address: "",
      },
      isdisabled: true,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
      ressList: [],
      redactId: "",
    };
  },
  watch: {
    addLocationForm: {
      handler: function (newValue, oldVal) {
        if (newValue.name && newValue.mobile && newValue.address) {
          this.isdisabled = false;
        } else {
          this.isdisabled = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getAddressListByUserId();
  },
  methods: {
    async deleteaddress(item) {
      const params = {
        mobile: item.mobile,
        id: item.id,
        isDelete: 1,
      };
      const { data, code } = await this.$ajax.updateAddress(params);
      this.getAddressListByUserId();
    },

    //设置默认地址
    async setIsCheck(item) {
      const { data, code } = await this.$ajax.setIsCheck({ id: item.id });
      if (code == 200) {
        this.getAddressListByUserId();
      }
    },
    //获取地址列表
    async getAddressListByUserId() {
      const { data, code } = await this.$ajax.getAddressListByUserId();
      this.ressList = data;
    },
    //保存
    async insert() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.addLocationForm.mobile) {
            if (!this.$reg.phone.test(this.addLocationForm.mobile)) {
              this.$message.error("请输入正确手机号");
              return;
            }
          }

          if (this.redactId) {
            this.updateAddress();
          } else {
            this.buttonLnsert();
          }
        } else {
          return false;
        }
      });
    },
    async buttonLnsert() {
      const params = {
        ...this.addLocationForm,
        isCheck: 0,
      };
      const { data, code } = await this.$ajax.insert(params);
      if (code == 200) {
        this.addLocationForm = {
          name: "",
          mobile: "",
          address: "",
        };
        this.isAdd = true;
        this.getAddressListByUserId();
      }
    },
    async updateAddress() {
      const params = {
        ...this.addLocationForm,
        isCheck: 0,
        id: this.redactId,
      };
      const { data, code } = await this.$ajax.updateAddress(params);
      if (code == 200) {
        this.addLocationForm = {
          name: "",
          mobile: "",
          address: "",
        };
        this.isAdd = true;
        this.getAddressListByUserId();
      }
      this.redactId = null;
    },
    //编辑
    redact(item) {
      this.redactId = item.id;
      this.addLocationForm.address = item.address;
      this.addLocationForm.mobile = item.mobile;
      this.addLocationForm.name = item.name;
      setTimeout(() => {
        this.isAdd = false;
        this.isdisabled = false;
      }, 500);
    },

    addLocation() {
      this.isAdd = false;
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("已滚动到底部");
        // 在这里执行你想要的操作
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shippingAddress {
  padding: 50px 56px;
  .button {
    margin-top: 169px !important;
  }
  &-pagw {
    width: 942px;
    height: 631px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector12315472x.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 30px 47px;
  }
  :deep(.el-form-item__error) {
    left: 43% !important;
    top: 123% !important;
  }
  .shippingAddress-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  span:hover {
    cursor: pointer;
  }

  .shippingAddress-list {
    height: 500px;
    overflow: auto;

    .li {
      width: 100%;
      height: 145px;
      flex-shrink: 0;
      background: url("https://cdn.bubbleplan.cn/static/user/Vect123or5642x.png")
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
      padding: 34px 30px;
    }
  }

  .userInfo-name-button {
    width: 100%;
    margin-top: 60px;
    display: flex;

    :deep(.el-button) {
      margin: auto;
      width: 144px;
      height: 61px;
      background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
        no-repeat;
      background-size: 100% 100%;
      border: none;
      margin: auto;
      color: #f1eae2;
      font-family: "TsangerYuMo";
      font-weight: bold;
      text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
        2px 2px 0 #533690;
      /* 模拟描边效果 */
      font-size: 26px;
    }

    // :deep(.el-button--success.is-disabled) {
    //   background: rgba(218, 255, 0, 0.4);
    //   border: none;
    // }
  }

  .shippingAddress-form {
    padding-left: 50px;
    :deep(.el-input) {
      width: 749px;
    }

    :deep(.el-input__inner) {
      width: 749px;
      height: 63px;
      background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 10px;
      border: none;
      font-size: 23px;
      color: #d9d2e4;
      text-align: center;
      font-family: "TsangerYuMo";
      font-weight: bold;
    }

    :deep(.el-form-item__label) {
      font-family: "TsangerYuMo";
      color: #fef5de;
      font-size: 24px;
      text-align: center;
      width: 100%;
      font-weight: bold;
      text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
        2px 2px 0 #341030;
      /* 模拟描边效果 */
    }
  }
}
</style>